import React from 'react';
import MainscaleItem from './MainscaleItem';
import PersonFormCard from './PersonFormCard';
import { Redirect } from 'react-router'
import PersonCard from './PersonCard';
import Box from '@material-ui/core/Box';
import MainscaleCanvasCard from './MainscaleCanvasCard';
import MainscalePdfExporter from '../exporter/MainscalePdfExporter';
import MainscaleChooseCategoryCard from './MainscaleChooseCategoryCard';
import SkippedItemsCard from './SkippedItemsCard';
import Button from '@material-ui/core/Button';
import Axios from 'axios';
import QualitativeQuantitativeChoiceCard from './QualitativeQuantitativeChoiceCard';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

// const ITEMS_API_ENDPOINT = "https://oia5jswo8i.execute-api.eu-central-1.amazonaws.com/dev/items";
const ITEMS_API_ENDPOINT = BACKEND_URL + "/items";


export default class MainscaleWorkflow extends React.Component {
    constructor(props) {
        super(props);

        //this.initialize(true);


        let stateObj = {
            items: null,
            currentItem: null,
            mainData: null,
            workflowStep: 1,
            skippedItems: [],
            maxItemIndex: 0
        };

        if (props.items) {
            stateObj.rawItems = props.items;
            stateObj.mainData = props.personData;
            stateObj.workflowStep = 2;
        }

        this.state = stateObj;

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
        this.disableBack = this.disableBack.bind(this);
        this.disableNext = this.disableNext.bind(this);
        this.handleMainData = this.handleMainData.bind(this);
        this.handleItemValueChange = this.handleItemValueChange.bind(this);
        this.handleDimensionChoice = this.handleDimensionChoice.bind(this);
        this.navigateToSkippedItemsList = this.navigateToSkippedItemsList.bind(this);
        this.handleSkippedItem = this.handleSkippedItem.bind(this);
        this.handleSkippedItemValueChange = this.handleSkippedItemValueChange.bind(this);
        this.backToItemWorkflow = this.backToItemWorkflow.bind(this);
        this.handlePdfDownload = this.handlePdfDownload.bind(this);
        this.checkForCategorySkip = this.checkForCategorySkip.bind(this);
        this.handleQuestionaireTypeChange = this.handleQuestionaireTypeChange.bind(this);

        if (props.setDownloadFn) {
            props.setDownloadFn(this.handlePdfDownload);
        }
    }

    initialize(constructor) {

        const postData = {
            token: this.props.token,
            productId: 1
        }

        // let response = await Axios.post("/api/getItems", postData);
        // let data = response.data;

        // console.log("AXIOS RESPONSE:");
        // console.log(response.data);

        let stateObj = {
            items: null,
            currentItem: null,
            mainData: null,
            workflowStep: 1,
            skippedItems: [],
            maxItemIndex: 0,
            questionaireType: null
        };

        if (constructor) {
            this.state = stateObj;
        } else {
            this.setState(stateObj);
        }
    }

    componentDidMount() {
        if (this.state.workflowStep === 1) {
            const postData = {
                token: this.props.token,
                productId: 1
            }

            Axios({
                method: 'post',
                url: ITEMS_API_ENDPOINT,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: postData
            }).then((response) => {
                this.setState({ rawItems: response.data });
            });
        }
    }

    handleBack() {
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = this.state.items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });
        this.setState({ currentItem: this.state.items[currentItemIndex - 1] });
    }

    //when 10 items were answered with 3, the category should be skipped
    checkForCategorySkip(currentItemIndex) {
        //check if last 10 items of category were declined with answer "3"
        var currentCategoryAbbrv = this.state.items[currentItemIndex].itemCategory.categoryAbbrv;
        var declinedCount = 0;
        for (var i = currentItemIndex; i >= 0; i--) {
            if (this.state.items[i].itemCategory.categoryAbbrv === currentCategoryAbbrv) {
                if (this.state.items[i].itemValue === "3") {
                    declinedCount++;

                    if (declinedCount > 9) {
                        // nextItemIndex = this.getFirstItemOfNextCategory(currentCategoryAbbrv, currentItemIndex);
                        return true;
                    }

                } else {
                    // declinedCount = 0;
                    return false;
                }
            } else {
                // declinedCount = 0;
                return false;
            }
        }
    }

    handleNext() {
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = this.state.items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });

        //this will be overidden if 10 items in one category were skipped
        var nextItemIndex = currentItemIndex + 1;

        if (this.checkForCategorySkip(currentItemIndex)) {
            var currentCategoryAbbrv = this.state.items[currentItemIndex].itemCategory.categoryAbbrv;
            nextItemIndex = this.getFirstItemOfNextCategory(currentCategoryAbbrv, currentItemIndex);
        }

        this.setState({
            currentItem: this.state.items[nextItemIndex],
            maxItemIndex: nextItemIndex
        });
    }

    getFirstItemOfNextCategory(categoryAbbrv, currentItemIndex) {
        //Find first item of next category
        for (var x = currentItemIndex; x < this.state.items.length; x++) {
            if (this.state.items[x].itemCategory.categoryAbbrv !== categoryAbbrv) {
                return x;
            }
        }

        //if no category with new items is following, null will be returned
        return null;
    }

    handleFinish() {
        //TODO: show Dialog if click was before all items were filled
        this.setState({ workflowStep: 5 });
    }

    disableBack() {
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = this.state.items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });

        if ((this.state.maxItemIndex - currentItemIndex) > 4) {
            return true;
        }
        if (currentItemIndex === 0) {
            return true;
        } else {
            return false;
        }
    }

    disableNext() {
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = this.state.items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });
        if (!this.state.items[currentItemIndex].itemValue) {
            return true;
        }
        if (this.checkForCategorySkip(currentItemIndex) && !this.getFirstItemOfNextCategory(this.state.items[currentItemIndex].itemCategory.categoryAbbrv, currentItemIndex)) {
            return true;
        }
        if (currentItemIndex < (this.state.items.length - 1)) {
            return false;
        } else {
            return true;
        }
    }

    handleMainData(data) {
        this.setState({
            mainData: data,
            workflowStep: 2
        });
    }

    navigateToSkippedItemsList() {
        this.setState({
            workflowStep: 99
        });
    }

    handleSkippedItem(item) {
        var skippedItems = this.state.skippedItems;
        skippedItems.push(item);
        this.setState({ skippedItems: skippedItems });
    }

    handleItemValueChange(value) {
        var items = this.state.items;
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });


        items[currentItemIndex].itemValue = value;
        this.setState({ items: items });
    }

    handleDimensionChoice(dimensions) {
        var items = this.state.rawItems;

        console.log("Dimensions!!!");
        console.log(dimensions);

        const getItemsByCategory = (items, categoryAbbrv) => {
            var categoryItems = [];
            items.forEach(element => {
                if (element.itemCategory.categoryAbbrv === categoryAbbrv) {
                    categoryItems.push(element);
                }
            });
            return categoryItems;
        };

        var filteredItems = [];
        dimensions.forEach(dim => {
            console.log(dim);
            filteredItems = filteredItems.concat(getItemsByCategory(items, dim));
        });

        console.log(filteredItems);
        this.setState({
            items: filteredItems,
            currentItem: filteredItems[0],
            workflowStep: 4
        });
    }

    handleSkippedItemValueChange(skippedItem) {
        var items = this.state.items;
        var skippedItemId = skippedItem.itemId;
        var itemIndex = items.findIndex(
            (item) => {
                if (item.itemId === skippedItemId) {
                    return true;
                }
                return false;
            });


        items[itemIndex].itemValue = skippedItem.itemValue;

        var skippedItems = this.state.skippedItems;
        var skippedItemIndex = skippedItems.findIndex(
            (item) => {
                if (item.itemId === skippedItemId) {
                    return true;
                }
                return false;
            });
        skippedItems.splice(skippedItemIndex, 1);

        this.setState({
            items: items,
            skippedItems: skippedItems
        });
    }

    backToItemWorkflow() {
        this.setState({ workflowStep: 4 });
    }

    showInterimResult() {
        console.log("Show Result!");
    }

    handlePdfDownload() {
        const mainscalePdfExporter = new MainscalePdfExporter(this.state.items, this.state.questionaireType, this.state.mainData.patient, this.state.mainData.assessor);
        mainscalePdfExporter.createPdf();
    }

    handleQuestionaireTypeChange(value) {
        this.setState({
            questionaireType: value,
            workflowStep: 3
        });
    }

    render() {
        let query = this.props.location.search;
        if (query === "?new") {
            this.initialize(false);
            return (<Redirect to={this.props.location.pathname} />);
        }

        //Skipping items cannot be accessed in the current version (this is intended!)
        if (this.state.workflowStep === 99) {
            return (
                <Box>
                    <Box m={2}>
                        <PersonCard patient={this.state.mainData.patient} assessor={this.state.mainData.assessor} />
                    </Box>
                    <Box m={2}>
                        <SkippedItemsCard backToItemWorkflow={this.backToItemWorkflow} handleSkippedItemValueChange={this.handleSkippedItemValueChange} items={this.state.skippedItems} />
                    </Box>
                </Box>
            );

        } else if (this.state.workflowStep === 5) {
            if (this.props.handleFinished) {
                this.props.handleFinished();
            }

            return (
                <Box>
                    <Box m={2}>
                        <PersonCard patient={this.state.mainData.patient} assessor={this.state.mainData.assessor} />
                    </Box>
                    <Box m={2}>
                        <MainscaleCanvasCard items={this.state.items} questionaireType={this.state.questionaireType} />
                    </Box>
                    <Box m={2}>
                        <Button variant="contained" color="primary" onClick={this.handlePdfDownload}>Ergebnis herunterladen</Button>
                    </Box>
                </Box>
            );

        } else if (this.state.workflowStep === 4) {
            return (
                <Box>
                    <Box m={2}>
                        <PersonCard patient={this.state.mainData.patient} assessor={this.state.mainData.assessor} />
                    </Box>
                    <Box m={2}>
                        <MainscaleItem items={this.state.items} skippedItems={this.state.skippedItems} questionaireType={this.state.questionaireType} workflow={true} disableBack={this.disableBack()} disableNext={this.disableNext()} handleFinish={this.handleFinish} handleBack={this.handleBack} handleNext={this.handleNext} handleItemValueChange={this.handleItemValueChange} item={this.state.currentItem} handleSkippedItem={this.handleSkippedItem} navigateToSkippedItemsList={this.navigateToSkippedItemsList} key={this.state.currentItem.itemId} />
                    </Box>
                </Box>
            );
        } else if (this.state.workflowStep === 3) {
            return (
                <Box m={2}>
                    <MainscaleChooseCategoryCard onSubmit={this.handleDimensionChoice} />
                </Box>
            )
        } else if (this.state.workflowStep === 2) {
            return (
                <Box m={2}>
                    <QualitativeQuantitativeChoiceCard onSubmit={this.handleQuestionaireTypeChange} />
                </Box>
            )
        } else if (this.state.workflowStep === 1) {
            console.log(this.state);
            return (
                <Box m={2}>
                    <PersonFormCard link="mainscale" onSubmit={this.handleMainData} />
                </Box>

            );
        }
    }
}